<script lang="ts" setup>
import { useLayoutStoreSearch } from '~/composables/useLayoutStoreSearch';
import { onClickOutside } from '@vueuse/core';
import type { SharedOffcanvas } from '~/components/shared/SharedOffcanvas.vue';
import ProductAlgoliaSuggestCategory from '~/components/product/ProductAlgoliaSuggestCategory.vue';
import { useMultipleProductsTracking } from '~/composables/tracking/useMultipleProductsTracking';

defineProps<{
    type?: string;
}>();

const { headerHeight } = useHeaderState();
const flyoutTopOffset = computed(() => (headerHeight.value ? headerHeight.value + 'px' : '100px'));
const closeBtnflyoutTopOffset = computed(() => (headerHeight.value ? headerHeight.value + 6 + 'px' : '130px'));
const offcanvas = inject<SharedOffcanvas>('offcanvas') as SharedOffcanvas;
const searchPopOver = ref(null);

const { searchResults, error, active, loading, getTotal, typingQuery, relatedCategories, MIN_ALLOWED_SEARCH_LENGTH } =
    useLayoutStoreSearch();
const { productIndex, categoryIndex } = useAlgolia();
const { trackAlgoliaObjectView } = useAlgoliaTracking();

const { trackViewSearchResults } = useSearchResultsTracking();

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const { isLoggedIn } = useUser();

const onForceLogin = () => {
    if (isLoggedIn.value) return;
    offcanvas.open('AccountLoginForm', 'layout-login-offcanvas');
};

// ignore inner clicks on both inputs otherwise they will close each other with outside click
onClickOutside(
    searchPopOver,
    () => {
        active.value = false;
    },
    {
        ignore: [
            '#search-input-desktop',
            '#search-input-mobile',
            '#suggest-hover-actions',
            '#suggest-hover-actions-wrapper',
        ],
    },
);

const { prepareAlgoliaProductsForTracking } = usePrepareForTracking();
const { trackMultipleProducts } = useMultipleProductsTracking();

const foundProductNumbers = computed(() => searchResults.value?.map((item) => item?.productNumber));
const foundProductObjectIds = computed(() => searchResults.value?.map((item) => item?.objectID));
const foundCategoryObjectIds = computed(() => relatedCategories.value?.map((item) => item?.objectID));

const { getAvailabilities } = useAvailability();
watch(searchResults, async () => {
    await getAvailabilities(foundProductNumbers.value);

    if (getTotal.value > 0) {
        trackMultipleProducts(
            'view_item_list',
            prepareAlgoliaProductsForTracking(searchResults.value, undefined, 'Search'),
        );

        trackAlgoliaObjectView({
            objectIDs: foundProductObjectIds.value,
        });
    }
});

watch(relatedCategories, (newValue) => {
    if (newValue?.length > 0) {
        trackAlgoliaObjectView({
            objectIDs: foundCategoryObjectIds.value,
            type: 'category',
        });
    }
});

const onOpenSearch = (searchTermTrigger: string) => {
    trackViewSearchResults(typingQuery.value, searchTermTrigger);
    active.value = false;
};

onUnmounted(() => {
    document.body.style.overflow = '';
});
</script>

<template>
    <div
        :id="`search-input-${type}`"
        ref="searchPopOver"
        class="container fixed overflow-y-auto h-auto max-h-[70dvh] bg-white border-gray-100 border-t-1 left-0 right-0 overflow-hidden rounded-b-md shadow-lg p-6"
        :style="{ top: flyoutTopOffset }"
    >
        <SharedLoader
            v-if="loading && getTotal === 0"
            class="!mx-auto"
            size="sm"
        />
        <div
            v-else-if="error || typingQuery.length < 3"
            class="text-center text-brand-danger"
        >
            {{ error ? $t(error) : $t('search.minLength', { min: MIN_ALLOWED_SEARCH_LENGTH }) }}
        </div>
        <div
            v-else-if="!loading && getTotal === 0"
            class="text-center"
        >
            {{ $t('errors.message-no-results') }}
        </div>
        <div
            v-else
            class="flex flex-col gap-5 md:flex-row z-1"
        >
            <button
                :aria-label="$t('close')"
                class="fixed right-2 rounded-full p-1.5 bg-transparent md:hidden z-1 bg-white/70"
                :style="{ top: closeBtnflyoutTopOffset }"
                type="button"
                @click="active = false"
            >
                <span class="sr-only">{{ $t('close') }}</span>
                <SvgoXMark class="w-5 h-5" />
            </button>
            <div
                v-if="relatedCategories?.length > 0"
                class="md:basis-[200px] shrink-0 flex flex-col gap-3 lg:gap-9 transition-all"
                :class="{ '!basis-0 min-w-0 min-h-0 opacity-0': relatedCategories?.length === 0 }"
            >
                <div class="flex flex-col gap-2">
                    <span class="mb-2 text-lg font-bold">{{ $t('search.matchingCategoriesLabel') }}</span>
                    <ProductAlgoliaSuggestCategory
                        v-for="(category, index) in relatedCategories"
                        :key="category.objectID"
                        :index-name="categoryIndex"
                        :position-index="index + 1"
                        :category="category"
                        @close-search="active = false"
                    />
                </div>
            </div>
            <div class="grow transition-all">
                <span class="text-lg font-bold">{{ $t('search.matchingProductsLabel') }} ({{ getTotal }})</span>
                <div class="grid grid-cols-1 md:gap-2 md:grid-cols-2 xl:grid-cols-3 mt-4">
                    <ProductAlgoliaSuggestSearch
                        v-for="(searchResult, index) in searchResults"
                        :key="searchResult.objectID"
                        :search-result="searchResult"
                        :is-logged-in="isLoggedIn"
                        :index-name="productIndex"
                        :position-index="index + 1"
                        @add-to-cart-not-allowed="onForceLogin"
                        @close-search="active = false"
                    />
                </div>
            </div>
        </div>

        <div
            v-if="!loading && getTotal > 0 && typingQuery.length >= 3"
            class="text-center mt-6 sticky bottom-0 search-submit"
        >
            <SharedButton
                :to="formatLink({ path: `/search`, query: { query: typingQuery } })"
                data-testid="search-button-submit"
                color="brand-primary"
                @click="onOpenSearch('enter result page')"
            >
                <span v-html="$t('search.showAllResults', { count: getTotal })" />
            </SharedButton>
            <div
                class="absolute bottom-[-1.5rem] left-[-1.5rem] w-[calc(100%_+_3rem)] h-[calc(100%_+_3rem)] bg-gradient-to-t from-white z-[-1]"
            ></div>
        </div>
    </div>
</template>
